import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

const coins = [
  "axie-infinity",
  "decentraland",
  "the-sandbox",
  "gala",
  "wax",
  "mobox",
  "illuvium",
  "aavegotchi",
  "ultra",
  "seedify-fund",
  "chain-guardians",
  "derace",
  "netvrk",
  "virtue-poker",
  "altura",
  "ufo-gaming",
  "mines-of-dalarnia",
  "sidus",
];

const title = "Gaming";
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={[title, "kategorie"]} title={title} />
      <CategoryTemplate title={title} coins={coins} coingeckoId="play-to-earn" />
    </Layout>
  );
};

export default Page;
